/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Badge, Button, Nav } from 'react-bootstrap';
import './index.css';
import { useCookies } from 'react-cookie';
import { clear, getItem } from '../../utils/storage';
import { client } from '../../utils/matrix';
import logoGoverno from '../../images/logo-governo.png';
import logoEu from '../../images/logo-eu.png';
import logoDfp from '../../images/funzione-pubblica.svg';
import logoPnrr from '../../images/logo-pnrr.png';
import logoFormez from '../../images/logo-formez.png';

export default function Header({
  handleOpen,
  showSidebarButton,
  areaOspite,
}) {
  const [notifica, setNotifica] = useState(0);
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
  // useEffect
  useEffect(() => {
    client?.on('event', () => {
      setNotifica(client?.getRooms().filter((el) => el.name === 'Notifiche')?.[0]?.notificationCounts.total);
    });
  }, []);

  return (
    <>
      <header className="it-header-wrapper">
        <div className="it-header-slim-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="it-header-slim-wrapper-content">
                  <a className="d-lg-block navbar-brand" href="http://www.funzionepubblica.gov.it/" target="_blank" rel="noreferrer">Dipartimento della funzione pubblica</a>
                  <div className="header-slim-right-zone">
                    {(client && getItem('identita')) && (
                      <Link
                        to="/area-riservata/notifiche"
                        className="btn btn-full"
                      >
                        <FontAwesomeIcon
                          aria-hidden="true"
                          icon="bell"
                          size="lg"
                        />
                      &nbsp;
                        <Badge bg="secondary">
                          {notifica}
                        </Badge>
                        <span className="sr-only">notifiche non lette</span>
                      </Link>
                    )}
                    <a
                      href="/"
                      className="btn btn-primary btn-full"
                      onClick={() => {
                        client?.logout();
                        clear();
                        removeCookie('loggedIn', { path: '/' });
                      }}
                    >
                      <FontAwesomeIcon
                        aria-hidden="true"
                        icon="sign-out-alt"
                        size="lg"
                      />
                      <span style={{ paddingLeft: '2px' }}>Logout</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="it-header-center-wrapper" style={{ paddingLeft: '18px' }}>
          {showSidebarButton && (
            <Button id="sidebarToggle" onClick={() => handleOpen()}>
              <FontAwesomeIcon aria-hidden="true" icon="bars" size="lg" />
              <span className="sr-only">Apertura sidebar</span>
            </Button>
          )}
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="it-header-center-content-wrapper">
                  <div className="it-brand-wrapper">
                    <a href="#" title="Vai alla pagina: Homepage">
                      <svg width="82" height="82" className="icon" aria-hidden="true">
                        <image xlinkHref={logoGoverno} alt="Logo Governo Italiano" />
                      </svg>
                      <div className="it-brand-text">
                        <div className="it-brand-title" style={{ fontSize: '48px' }}>RiVa</div>
                        <div className="it-brand-tagline d-none d-lg-block">La gestione strategica delle Risorse umane per creare Valore pubblico</div>
                      </div>
                    </a>
                  </div>

                  <div className="d-flex flex-column align-items-end h-100">
                    <div className="logo-long d-none d-lg-block mb-3">
                      <img height="40px" className="me-3" src={logoEu} alt="Logo Next Generation EU" />
                      <img height="40px" className="me-3" src={logoDfp} alt="Logo Dipartimento della Funzione Pubblica" />
                      <img height="40px" className="me-3" src={logoPnrr} alt="Logo PNRR" />
                      <img height="40px" className="me-3" src={logoFormez} alt="Logo Formez" />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {!areaOspite && (
      <Navbar bg="blue" collapseOnSelect expand="sm" variant="grey" role="banner" aria-label="Menu di navigazione">
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <FontAwesomeIcon aria-hidden="true" icon="ellipsis-v" size="lg" color="white" />
            <span className="sr-only">Apertura opzioni header</span>
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav" style={{ flexGrow: 0 }}>
            <Nav className="me-auto">
              <Link to="/area-riservata/scelta-identita?from=area-riservata" style={{ color: 'white', textDecoration: 'none' }} className="m-2">
                <FontAwesomeIcon aria-hidden="true" icon={['far', 'address-card']} size="lg" />
                {' '}
                {getItem('identita') ? (JSON.parse(getItem('identita')))?.nomeIdentita : 'Scelta identità'}
              </Link>
              <Link to="/area-riservata/account" style={{ color: 'white', textDecoration: 'none' }} className="m-2">
                <FontAwesomeIcon aria-hidden="true" icon="user-circle" size="lg" />
                {' '}
                Account
              </Link>
              <Link to="/area-riservata/help-desk" style={{ color: 'white', textDecoration: 'none' }} className="m-2">
                <FontAwesomeIcon aria-hidden="true" icon="handshake-angle" size="lg" />
                {' '}
                Help Desk
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      )}
    </>
  );
}
