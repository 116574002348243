/* eslint-disable max-len */
import React, { Suspense, lazy } from 'react';
import {
  Router, Switch,
} from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import history from './utils/history';
import PublicLayout from './layouts/PublicLayout';
import PrivateLayout from './layouts/PrivateLayout/index';
// import GestioneIdentita from './pages/area-riservata/amministrazione/gestione-identita';
// import AggiungIdentita from './pages/area-riservata/amministrazione/gestione-identita/aggiungi';
// import DettaglioIdentita from './pages/area-riservata/amministrazione/gestione-identita/_id/dettaglio';
// import ModificaIdentita from './pages/area-riservata/amministrazione/gestione-identita/_id/modifica';
// import GestioneTagRuoli from './pages/area-riservata/amministrazione/gestione-tag-ruolo';
// import AggiungiTagRuolo from './pages/area-riservata/amministrazione/gestione-tag-ruolo/aggiungi';
// import GestioneTagIdentita from './pages/area-riservata/amministrazione/gestione-tag-identita';
// import AggiungiTagIdentita from './pages/area-riservata/amministrazione/gestione-tag-identita/aggiungi';
// import GestioneRuoli from './pages/area-riservata/amministrazione/gestione-ruolo';
// import FormNuovoRuolo from './pages/area-riservata/amministrazione/gestione-ruolo/inserimento';
// import FormModificaRuolo from './pages/area-riservata/amministrazione/gestione-ruolo/_id/modifica';
// import FormDettaglioRuolo from './pages/area-riservata/amministrazione/gestione-ruolo/_id/dettaglio';
// import GestionePiattaforma from './pages/area-riservata/amministrazione/gestione-piattaforma';
import TornaSu from './components/TornaSu';
import ScrollToTop from './utils/ScrollToTop';
import SpinnerBody from './components/Shared/SpinnerBody';

const HomePage = lazy(() => import('./pages'));
const Login = lazy(() => import('./pages/pagina-login'));
const Progetto = lazy(() => import('./pages/progetto'));
const Approfondimenti = lazy(() => import('./pages/approfondimenti'));
const ApprofondimentiDettaglio = lazy(() => import('./pages/approfondimenti/dettaglio'));
const FaqPubblica = lazy(() => import('./pages/faq'));
const DichiarazioneAccessibilita = lazy(() => import('./pages/dichiarazione-accessibilità'));
const AreaAmministrazioni = lazy(() => import('./pages/area-amministrazioni'));
const LoginSpid = lazy(() => import('./pages/login'));
const ErrorPage = lazy(() => import('./pages/error'));
const Error403Page = lazy(() => import('./pages/error403'));
const AttivaRichiestaAccreditamentoByHash = lazy(() => import('./pages/attiva-richiesta-accreditamento/hash'));
// INIZIO PATH COMPILAZIONE QUESTIONARI AREA PUBBLICA
const ConfermaInvioQuestionario = lazy(() => import('./pages/questionari/conferma'));
const AccettazioneTemplateEsterno = lazy(() => import('./pages/questionari/_idQuestionario'));
const CompilaQuestionarioEsterno = lazy(() => import('./pages/questionari/compila-questionario'));
// FINE PATH COMPILAZIONE QUESTIONARI AREA PUBBLICA

// INIZIO PATH AREA RISERVATA
const Home = lazy(() => import('./pages/area-riservata'));
const UserInformation = lazy(() => import('./pages/area-riservata/account'));
const SceltaIdentita = lazy(() => import('./pages/area-riservata/scelta-identita'));
const Notifiche = lazy(() => import('./pages/area-riservata/notifiche'));
const AreaContrattuale = lazy(() => import('./pages/area-riservata/area-contrattuale'));
const FamiglieProfessionali = lazy(() => import('./pages/area-riservata/famiglie-professionali'));
const FamigliaProfessionaliDettaglio = lazy(() => import('./pages/area-riservata/famiglie-professionali/dettaglio'));
const DimensioniProfessionali = lazy(() => import('./pages/area-riservata/dimensioni-professionali'));
const DimensioneProfessionaleDettaglio = lazy(() => import('./pages/area-riservata/dimensioni-professionali/dettaglio'));
const AreeCompetenze = lazy(() => import('./pages/area-riservata/aree-competenze'));
const AmbitiRuolo = lazy(() => import('./pages/area-riservata/ambiti-ruolo'));
const Competenze = lazy(() => import('./pages/area-riservata/competenze'));
const RicercaAICompetenze = lazy(() => import('./pages/area-riservata/competenze/ricerca-semantica-ai'));
const Grading = lazy(() => import('./pages/area-riservata/grading'));

// INIZIO PATH PROFILI DI RUOLO
const ProfiliRuolo = lazy(() => import('./pages/area-riservata/profili-ruolo'));
const RicercaAIProfiloRuolo = lazy(() => import('./pages/area-riservata/profili-ruolo/ricerca-semantica-ai'));
const ProfiloRuoloDettaglio = lazy(() => import('./pages/area-riservata/profili-ruolo/dettaglio'));
// FINE PATH PROFILI DI RUOLO

// INIZIO PATH PROFILI PROFESSIONALI
const ProfiliProfessionali = lazy(() => import('./pages/area-riservata/profili-professionali'));
const RicercaAIProfiloProfessionale = lazy(() => import('./pages/area-riservata/profili-professionali/ricerca-semantica-ai'));
const ProfiloProfessionaleDettaglio = lazy(() => import('./pages/area-riservata/profili-professionali/dettaglio'));
// FINE PATH PROFILI PROFESSIONALI

// INIZIO PATH ASSESSMENT
const CicliAssessment = lazy(() => import('./pages/area-riservata/cicli-assessment'));
const AssegnazioneCicloUtenti = lazy(() => import('./pages/area-riservata/cicli-assessment/assegnazione-ciclo-utenti'));
const AnalisiEsitiAssessment = lazy(() => import('./pages/area-riservata/analisi-esiti'));
const AnalisiEsitiAssessmentDettaglioDipendenti = lazy(() => import('./pages/area-riservata/analisi-esiti/dettaglioDipendenti'));
const AnalisiEsitiAssessmentDettaglio = lazy(() => import('./pages/area-riservata/analisi-esiti/dettaglio'));
const AutoAssessment = lazy(() => import('./pages/area-riservata/auto-assessment'));
const AutoAssessmentDettaglio = lazy(() => import('./pages/area-riservata/auto-assessment/dettaglio'));
const EteroAssessment = lazy(() => import('./pages/area-riservata/etero-assessment'));
const EteroAssessmentDettaglioDipendenti = lazy(() => import('./pages/area-riservata/etero-assessment/dettaglioDipendenti'));
const EteroAssessmentDettaglio = lazy(() => import('./pages/area-riservata/etero-assessment/dettaglio'));
// FINE PATH ASSESSMENT

// INIZIO DEMO ASSESSMENT
const DemoAssessment = lazy(() => import('./pages/area-riservata/demo-assessment/ciclo-assessment'));
const DemoElencoAssessment = lazy(() => import('./pages/area-riservata/demo-assessment/elenco-assessment'));
const DemoDipendentiAssessment = lazy(() => import('./pages/area-riservata/demo-assessment/elenco-assessment/elenco'));
const DemoAutoAssessment = lazy(() => import('./pages/area-riservata/demo-assessment/auto-assesment'));
const DemoEteroAssessment = lazy(() => import('./pages/area-riservata/demo-assessment/etero-assessment'));
const DemoAssessmentCompleto = lazy(() => import('./pages/area-riservata/demo-assessment/assessment-completo'));
// FINE DEMO ASSESSMENT

// INIZIO BPM
// const DemoBpm = lazy(() => import('./pages/area-riservata/demo-bpm'));
// const AggiungiPiano = lazy(() => import('./pages/area-riservata/demo-bpm/aggiungi-piano'));
// const VisualizzaPiano = lazy(() => import('./pages/area-riservata/demo-bpm/visualizza-piano'));

const Bpm = lazy(() => import('./pages/area-riservata/bpm'));
const DefinizioneCosti = lazy(() => import('./pages/area-riservata/bpm/aggiungi-piano/definizione-costi'));
const Prioritizzazione = lazy(() => import('./pages/area-riservata/bpm/aggiungi-piano/prioritizzazione'));
const RichiesteFabbisogniPolicyMaker = lazy(() => import('./pages/area-riservata/bpm/aggiungi-piano/richieste-fabbisogni/pm'));
const VisualizzaPiano = lazy(() => import('./pages/area-riservata/bpm/visualizza-piano'));
const ScelteCoperturaPolicyMaker = lazy(() => import('./pages/area-riservata/bpm/aggiungi-piano/scelte-copertura'));
const SintesiScelteCopertura = lazy(() => import('./pages/area-riservata/bpm/aggiungi-piano/scelte-copertura/sintesi'));
const RichiesteFabbisogniDirettore = lazy(() => import('./pages/area-riservata/bpm/aggiungi-piano/richieste-fabbisogni/direttore'));
// FINE BPM

// INIZIO PATH TEMPLATE
const Template = lazy(() => import('./pages/area-riservata/template'));
const GestioneTemplate = lazy(() => import('./pages/area-riservata/template/gestione-template'));
const VisualizzaTemplate = lazy(() => import('./pages/area-riservata/template/visualizza-template'));
const VisualizzaStatistiche = lazy(() => import('./pages/area-riservata/template/visualizza-statistiche'));
// FINE PATH TEMPLATE

// INIZIO PATH QUESTIONARI
const Questionari = lazy(() => import('./pages/area-riservata/questionari'));
const CompilaQuestionario = lazy(() => import('./pages/area-riservata/questionari/compilaQuestionario'));
const VisualizzaQuestionario = lazy(() => import('./pages/area-riservata/questionari/visualizzaQuestionario'));
// FINE PATH QUESTIONARI

// INIZIO STATISTICHE
const ReportingSistema = lazy(() => import('./pages/area-riservata/statistiche/index/ReportingSistema'));
const ReportingMatrice = lazy(() => import('./pages/area-riservata/statistiche/index/ReportingMatrice'));
const ReportingAssessment = lazy(() => import('./pages/area-riservata/statistiche/index/ReportingAssessment'));
// FINE STATISTICHE

// INIZIO SCHEDA COMPETENZE E ASSEGNAZIONE PROFILO
const SchedaCompetenze = lazy(() => import('./pages/area-riservata/scheda-competenze/pages'));
const ListaUtentiSchede = lazy(() => import('./pages/area-riservata/scheda-competenze/pages/lista-utenti-schede'));
const AssegnazioneProfilo = lazy(() => import('./pages/area-riservata/assegnazione-profilo'));
const ModificaAssegnazioneProfilo = lazy(() => import('./pages/area-riservata/assegnazione-profilo/modifica'));
// FINE SCHEDA COMPETENZE E ASSEGNAZIONE PROFILO

// INIZIO PATH FORUM
const Forum = lazy(() => import('./pages/area-riservata/forum'));
const ForumMessaggi = lazy(() => import('./pages/area-riservata/forum/messaggi'));
const ForumSezione = lazy(() => import('./pages/area-riservata/forum/sezione'));
const ModificaSezione = lazy(() => import('./pages/area-riservata/forum/modifica'));
// FINE PATH FORUM

// INIZIO PATH AMMINISTRAZIONE
const Amministrazione = lazy(() => import('./pages/area-riservata/amministrazione'));
const GestisciUtenti = lazy(() => import('./pages/area-riservata/amministrazione/gestione-utenti'));
const DettaglioUtentePerAmministratore = lazy(() => import('./pages/area-riservata/amministrazione/gestione-utenti/dettaglio'));
const ModificaUtentePerAmministratore = lazy(() => import('./pages/area-riservata/amministrazione/gestione-utenti/dettaglio/modifica'));
const GestioneUtentiMassiva = lazy(() => import('./pages/area-riservata/amministrazione/gestione-utenti-massiva'));
const DettaglioUtentiMassivo = lazy(() => import('./pages/area-riservata/amministrazione/gestione-utenti-massiva/dettaglio'));
const GestioneRichiesteIdentita = lazy(() => import('./pages/area-riservata/amministrazione/gestione-richieste-identita'));
const DettaglioRichiestaIdentitaAmministratore = lazy(() => import('./pages/area-riservata/amministrazione/gestione-richieste-identita/_id/dettaglio'));
const ElencoEnti = lazy(() => import('./pages/area-riservata/amministrazione/elenco-enti'));
// FINE PATH AMMINISTRAZIONE

// INIZIO PATH RICHIESTA IDENTITA
const GestioneRichiesteIdentitaPerUtente = lazy(() => import('./pages/area-riservata/richiesta-identita'));
const NuovaRichiestaIdentitaPerUtente = lazy(() => import('./pages/area-riservata/richiesta-identita/aggiungi'));
const DettaglioRichiestaIdentitaPerUtente = lazy(() => import('./pages/area-riservata/richiesta-identita/_id/dettaglio'));
const ModificaRichiestaIdentitaPerUtente = lazy(() => import('./pages/area-riservata/richiesta-identita/_id/modifica'));
// FINE PATH RICHIESTA IDENTITA

// INIZIO PATH NEWS
const GestioneNews = lazy(() => import('./pages/area-riservata/gestione-news'));
const DettaglioNews = lazy(() => import('./pages/area-riservata/gestione-news/dettaglio'));
const AggiungiNews = lazy(() => import('./pages/area-riservata/gestione-news/aggiungi'));
const ModificaNews = lazy(() => import('./pages/area-riservata/gestione-news/modifica'));
const GestioneNewsEsterne = lazy(() => import('./pages/area-riservata/gestione-news-esterne'));
const DettaglioNewsEsterna = lazy(() => import('./pages/area-riservata/gestione-news-esterne/dettaglio'));
const AggiungiNewsEsterna = lazy(() => import('./pages/area-riservata/gestione-news-esterne/aggiungi'));
const ModificaNewsEsterna = lazy(() => import('./pages/area-riservata/gestione-news-esterne/modifica'));
// FINE PATH NEWS

// INIZIO PATH FAQ E GESTIONE FAQ
const GestioneFaq = lazy(() => import('./pages/area-riservata/gestione-faq'));
const GestioneFaqPubbliche = lazy(() => import('./pages/area-riservata/gestione-faq-esterne'));
const AggiungiFaqPubblica = lazy(() => import('./pages/area-riservata/gestione-faq-esterne/aggiungi'));
const ModificaFaqPubblica = lazy(() => import('./pages/area-riservata/gestione-faq-esterne/modifica'));
const AggiungiFaq = lazy(() => import('./pages/area-riservata/gestione-faq/aggiungi'));
const ModificaFaq = lazy(() => import('./pages/area-riservata/gestione-faq/modifica'));
// FINE PATH FAQ E GESTIONE FAQ

// INIZIO PATH MATRICE CORRISPONDENZA
const MatriceCorrispondenzaProfili = lazy(() => import('./pages/area-riservata/matrice-corrispondenza-profili'));
const CaricaListaProfili = lazy(() => import('./pages/area-riservata/matrice-corrispondenza-profili/carica-lista-profili'));
const DettaglioCaricaProfili = lazy(() => import('./pages/area-riservata/matrice-corrispondenza-profili/carica-lista-profili/dettaglio'));
const ElaborazioneCorrispondenzeAI = lazy(() => import('./pages/area-riservata/matrice-corrispondenza-profili/elaborazione-ai'));
// FINE PATH MATRICE CORRISPONDENZA

// INIZIO PATH CHAT
const Chat = lazy(() => import('./pages/area-riservata/chat'));
// FINE PATH CHAT

// INIZIO PATH HELP DESK
const HelpDesk = lazy(() => import('./pages/area-riservata/help-desk'));

// INIZIO PATH TUTORIAL
const Tutorial = lazy(() => import('./pages/area-riservata/tutorial'));
// FINE PATH TUTORIAL

const PageNotFound = lazy(() => import('./pages/NotFound'));

// FINE PATH AREA RISERVATA

function App() {
  library.add(fab, fas, far);
  return (
    <div style={{ overflowX: 'hidden' }}>
      <Router history={history}>
        <Suspense fallback={(
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            margin: 0,
          }}
          >
            <SpinnerBody />
          </div>
        )}
        >
          <Switch>
            <PublicLayout path="/" exact component={HomePage} />
            <PublicLayout path="/pagina-login" exact component={Login} />
            <PublicLayout path="/progetto" exact component={Progetto} />
            <PublicLayout path="/approfondimenti" exact component={Approfondimenti} />
            <PublicLayout path="/approfondimenti/:idNews" exact component={ApprofondimentiDettaglio} />
            <PublicLayout path="/faq" exact component={FaqPubblica} />
            <PublicLayout path="/dichiarazione-accessibilita" exact component={DichiarazioneAccessibilita} />
            <PublicLayout path="/area-amministrazioni" exact component={AreaAmministrazioni} />
            <PublicLayout path="/login" exact component={LoginSpid} />
            <PublicLayout path="/error" exact component={ErrorPage} />
            <PublicLayout path="/error-unauthorized" exact component={Error403Page} />
            <PublicLayout path="/attiva-richiesta-accreditamento/:hash" exact component={AttivaRichiestaAccreditamentoByHash} />

            {/* INIZIO PATH COMPILAZIONE QUESTIONARI AREA PUBBLICA  */}
            <PublicLayout path="/questionari/conferma" exact component={ConfermaInvioQuestionario} />
            <PublicLayout path="/questionari/:idTemplate" exact component={AccettazioneTemplateEsterno} />
            <PublicLayout path="/questionari/compila-questionario/:idQuestionario" exact component={CompilaQuestionarioEsterno} />
            {/* FINE PATH COMPILAZIONE QUESTIONARI AREA PUBBLICA  */}

            {/* INIZIO PATH AREA RISERVATA  */}
            <PrivateLayout path="/area-riservata" exact component={Home} />
            <PrivateLayout path="/area-riservata/account" exact component={UserInformation} />
            <PrivateLayout path="/area-riservata/scelta-identita" exact component={SceltaIdentita} />
            <PrivateLayout path="/area-riservata/notifiche" exact component={Notifiche} />
            <PrivateLayout path="/area-riservata/area-contrattuale" exact component={AreaContrattuale} />
            <PrivateLayout path="/area-riservata/famiglie-professionali" exact component={FamiglieProfessionali} />
            <PrivateLayout path="/area-riservata/famiglie-professionali/nuovo" exact component={FamigliaProfessionaliDettaglio} />
            <PrivateLayout path="/area-riservata/famiglie-professionali/:idFamigliaProfessionale" exact component={FamigliaProfessionaliDettaglio} />
            <PrivateLayout path="/area-riservata/dimensioni-professionali" exact component={DimensioniProfessionali} />
            <PrivateLayout path="/area-riservata/dimensioni-professionali/nuovo" exact component={DimensioneProfessionaleDettaglio} />
            <PrivateLayout path="/area-riservata/dimensioni-professionali/:idDimensioneProfessionale" exact component={DimensioneProfessionaleDettaglio} />
            <PrivateLayout path="/area-riservata/aree-competenze" exact component={AreeCompetenze} />
            <PrivateLayout path="/area-riservata/ambiti-ruolo" exact component={AmbitiRuolo} />
            <PrivateLayout path="/area-riservata/competenze/:tipoCompetenza" exact component={Competenze} />
            <PrivateLayout path="/area-riservata/competenze/:tipoCompetenza/ricerca-ai" exact component={RicercaAICompetenze} />
            <PrivateLayout path="/area-riservata/grading" exact component={Grading} />

            {/* INIZIO PATH PROFILI DI RUOLO */}
            <PrivateLayout path="/area-riservata/profili-ruolo" exact component={ProfiliRuolo} />
            <PrivateLayout path="/area-riservata/profili-ruolo/ricerca-ai" exact component={RicercaAIProfiloRuolo} />
            <PrivateLayout path="/area-riservata/profili-ruolo/:idProfiloRuolo" exact component={ProfiloRuoloDettaglio} />
            {/* FINE PATH PROFILI DI RUOLO */}

            {/* INIZIO PATH PROFILI PROFESSIONALI */}
            <PrivateLayout path="/area-riservata/profili-professionali" exact component={ProfiliProfessionali} />
            <PrivateLayout path="/area-riservata/profili-professionali/ricerca-ai" exact component={RicercaAIProfiloProfessionale} />
            <PrivateLayout path="/area-riservata/profili-professionali/:idProfiloProfessionale" exact component={ProfiloProfessionaleDettaglio} />
            {/* FINE PATH PROFILI PROFESSIONALI */}

            {/* INIZIO PATH ASSESSMENT */}
            <PrivateLayout path="/area-riservata/assessment" exact component={CicliAssessment} />
            <PrivateLayout path="/area-riservata/assessment/:idCicloAssessment/assegnazione-ciclo-utenti" exact component={AssegnazioneCicloUtenti} />
            <PrivateLayout path="/area-riservata/analisi-assessment" exact component={AnalisiEsitiAssessment} />
            <PrivateLayout path="/area-riservata/analisi-assessment/:idCicloAssessment" exact component={AnalisiEsitiAssessmentDettaglioDipendenti} />
            <PrivateLayout path="/area-riservata/analisi-assessment/:idCicloAssessment/:idUser" exact component={AnalisiEsitiAssessmentDettaglio} />
            <PrivateLayout path="/area-riservata/auto-assessment" exact component={AutoAssessment} />
            <PrivateLayout path="/area-riservata/auto-assessment/:idCicloAssessment" exact component={AutoAssessmentDettaglio} />
            <PrivateLayout path="/area-riservata/etero-assessment" exact component={EteroAssessment} />
            <PrivateLayout path="/area-riservata/etero-assessment/:idCicloAssessment" exact component={EteroAssessmentDettaglioDipendenti} />
            <PrivateLayout path="/area-riservata/etero-assessment/:idCicloAssessment/:idUser" exact component={EteroAssessmentDettaglio} />
            {/* FINE PATH ASSESSMENT */}

            {/* INIZIO DEMO ASSESSMENT */}
            <PrivateLayout path="/area-riservata/demo-assessment/ciclo-assessment" exact component={DemoAssessment} />
            <PrivateLayout path="/area-riservata/demo-assessment/elenco-assessment" exact component={DemoElencoAssessment} />
            <PrivateLayout path="/area-riservata/demo-assessment/elenco-assessment/elenco" exact component={DemoDipendentiAssessment} />
            <PrivateLayout path="/area-riservata/demo-assessment/auto-assessment" exact component={DemoAutoAssessment} />
            <PrivateLayout path="/area-riservata/demo-assessment/etero-assessment" exact component={DemoEteroAssessment} />
            <PrivateLayout path="/area-riservata/demo-assessment/assessment-completo" exact component={DemoAssessmentCompleto} />
            {/* FINE DEMO ASSESSMENT */}

            {/* INIZIO BPM */}
            {/* <PrivateLayout path="/area-riservata/demo-bpm" exact component={DemoBpm} />
            <PrivateLayout path="/area-riservata/demo-bpm/aggiungi-piano" exact component={AggiungiPiano} />
            <PrivateLayout path="/area-riservata/demo-bpm/visualizza-piano" exact component={VisualizzaPiano} /> */}
            <PrivateLayout path="/area-riservata/bpm" exact component={Bpm} />
            <PrivateLayout path="/area-riservata/bpm/aggiungi-piano/:idProcesso/definizione-costi" exact component={DefinizioneCosti} />
            <PrivateLayout path="/area-riservata/bpm/aggiungi-piano/:idProcesso/prioritizzazione" exact component={Prioritizzazione} />
            <PrivateLayout path="/area-riservata/bpm/aggiungi-piano/:idProcesso/lista-richieste-fabbisogni" exact component={RichiesteFabbisogniPolicyMaker} />
            <PrivateLayout path="/area-riservata/bpm/aggiungi-piano/:idProcesso/richiesta-fabbisogni" exact component={RichiesteFabbisogniDirettore} />
            <PrivateLayout path="/area-riservata/bpm/aggiungi-piano/:idProcesso/scelte-copertura" exact component={ScelteCoperturaPolicyMaker} />
            <PrivateLayout path="/area-riservata/bpm/aggiungi-piano/:idProcesso/scelte-copertura/sintesi" exact component={SintesiScelteCopertura} />
            <PrivateLayout path="/area-riservata/bpm/visualizza-piano/:idProcesso" exact component={VisualizzaPiano} />
            {/* FINE BPM */}

            {/* INIZIO PATH TEMPLATE */}
            <PrivateLayout path="/area-riservata/template" exact component={Template} />
            <PrivateLayout path="/area-riservata/template/gestione-template/:idTemplate" exact component={GestioneTemplate} />
            <PrivateLayout path="/area-riservata/template/visualizza-template/:idTemplate" exact component={VisualizzaTemplate} />
            <PrivateLayout path="/area-riservata/template/visualizza-statistiche/:idTemplate" exact component={VisualizzaStatistiche} />
            {/* FINE PATH TEMPLATE */}

            {/* INIZIO PATH QUESTIONARI */}
            <PrivateLayout path="/area-riservata/questionari" exact component={Questionari} />
            <PrivateLayout path="/area-riservata/questionari/compila-questionario/:idQuestionario" exact component={CompilaQuestionario} />
            <PrivateLayout path="/area-riservata/questionari/visualizza-questionario/:idQuestionario" exact component={VisualizzaQuestionario} />
            {/* FINE PATH QUESTIONARI */}

            {/* INIZIO STATISTICHE */}
            <PrivateLayout path="/area-riservata/statistiche-sistema-professionale" exact component={ReportingSistema} />
            <PrivateLayout path="/area-riservata/statistiche-matrice-profili-professionali" exact component={ReportingMatrice} />
            <PrivateLayout path="/area-riservata/statistiche-assessment" exact component={ReportingAssessment} />
            {/* FINE STATISTICHE */}

            {/* INIZIO SCHEDA COMPETENZE E ASSEGNAZIONE PROFILO */}
            <PrivateLayout path="/area-riservata/scheda-competenze" exact component={SchedaCompetenze} />
            <PrivateLayout path="/area-riservata/lista-utenti-schede" exact component={ListaUtentiSchede} />
            <PrivateLayout path="/area-riservata/scheda-competenza/:idDipendente" exact component={SchedaCompetenze} />
            <PrivateLayout path="/area-riservata/assegnazione-profilo" exact component={AssegnazioneProfilo} />
            <PrivateLayout path="/area-riservata/assegnazione-profilo/modifica/:idUtente" exact component={ModificaAssegnazioneProfilo} />
            {/* FINE SCHEDA COMPETENZE E ASSEGNAZIONE PROFILO */}

            {/* INIZIO PATH FORUM */}
            <PrivateLayout path="/area-riservata/forum" exact component={Forum} />
            <PrivateLayout path="/area-riservata/forum/messaggi" exact component={ForumMessaggi} />
            <PrivateLayout path="/area-riservata/forum/sezione" exact component={ForumSezione} />
            <PrivateLayout path="/area-riservata/forum/edit/:idSezione" exact component={ModificaSezione} />
            {/* FINE PATH FORUM */}

            {/* INIZIO PATH AMMINISTRAZIONE */}
            <PrivateLayout path="/area-riservata/amministrazione" exact component={Amministrazione} />
            <PrivateLayout path="/area-riservata/amministrazione/gestione-utenti" exact component={GestisciUtenti} />
            <PrivateLayout path="/area-riservata/amministrazione/gestione-utenti/:idUtente/dettaglio" exact component={DettaglioUtentePerAmministratore} />
            <PrivateLayout path="/area-riservata/amministrazione/gestione-utenti/:idUtente/modifica" exact component={ModificaUtentePerAmministratore} />
            <PrivateLayout path="/area-riservata/amministrazione/gestione-utenti-massiva" exact component={GestioneUtentiMassiva} />
            <PrivateLayout path="/area-riservata/amministrazione/gestione-utenti-massiva/:idBatch/dettaglio" exact component={DettaglioUtentiMassivo} />
            {/* <PrivateLayout path="/area-riservata/amministrazione/gestione-identita" exact component={GestioneIdentita} />
            <PrivateLayout path="/area-riservata/amministrazione/gestione-identita/aggiungi" exact component={AggiungIdentita} />
            <PrivateLayout path="/area-riservata/amministrazione/gestione-identita/:idIdentita/dettaglio" exact component={DettaglioIdentita} />
            <PrivateLayout path="/area-riservata/amministrazione/gestione-identita/:idIdentita/modifica" exact component={ModificaIdentita} />
            <PrivateLayout path="/area-riservata/amministrazione/gestione-tag-ruolo" exact component={GestioneTagRuoli} />
            <PrivateLayout path="/area-riservata/amministrazione/gestione-tag-ruolo/aggiungi" exact component={AggiungiTagRuolo} />
            <PrivateLayout path="/area-riservata/amministrazione/gestione-tag-identita" exact component={GestioneTagIdentita} />
            <PrivateLayout path="/area-riservata/amministrazione/gestione-tag-identita/aggiungi" exact component={AggiungiTagIdentita} />
            <PrivateLayout path="/area-riservata/amministrazione/gestione-ruolo" exact component={GestioneRuoli} />
            <PrivateLayout path="/area-riservata/amministrazione/gestione-ruolo/inserimento" exact component={FormNuovoRuolo} />
            <PrivateLayout path="/area-riservata/amministrazione/gestione-ruolo/:idRuolo/modifica" exact component={FormModificaRuolo} />
            <PrivateLayout path="/area-riservata/amministrazione/gestione-ruolo/:idRuolo/dettaglio" exact component={FormDettaglioRuolo} />
            <PrivateLayout path="/area-riservata/amministrazione/gestione-piattaforma" exact component={GestionePiattaforma} /> */}
            <PrivateLayout path="/area-riservata/amministrazione/gestione-richieste-identita" exact component={GestioneRichiesteIdentita} />
            <PrivateLayout path="/area-riservata/amministrazione/gestione-richieste-identita/:idRichiesta" exact component={DettaglioRichiestaIdentitaAmministratore} />
            <PrivateLayout path="/area-riservata/amministrazione/elenco-enti" exact component={ElencoEnti} />
            {/* FINE PATH AMMINISTRAZIONE */}

            {/* INIZIO PATH RICHIESTA IDENTITA */}
            <PrivateLayout path="/area-riservata/richiesta-identita" exact component={GestioneRichiesteIdentitaPerUtente} code="Y20" />
            <PrivateLayout path="/area-riservata/richiesta-identita/aggiungi" exact component={NuovaRichiestaIdentitaPerUtente} code="Y21" />
            <PrivateLayout path="/area-riservata/richiesta-identita/:idRichiesta" exact component={DettaglioRichiestaIdentitaPerUtente} code="Y22" />
            <PrivateLayout path="/area-riservata/richiesta-identita/:idRichiesta/modifica" exact component={ModificaRichiestaIdentitaPerUtente} />
            {/* FINE PATH RICHIESTA IDENTITA */}

            {/* INIZIO PATH NEWS */}
            <PrivateLayout path="/area-riservata/news" exact component={GestioneNews} />
            <PrivateLayout path="/area-riservata/news/dettaglio/:id" exact component={DettaglioNews} />
            <PrivateLayout path="/area-riservata/gestione-news" exact component={GestioneNews} />
            <PrivateLayout path="/area-riservata/gestione-news/modifica/:idNews" exact component={ModificaNews} />
            <PrivateLayout path="/area-riservata/gestione-news/aggiungi" exact component={AggiungiNews} />
            <PrivateLayout path="/area-riservata/gestione-news/dettaglio/:id" exact component={DettaglioNews} />
            <PrivateLayout path="/area-riservata/gestione-news-esterne" exact component={GestioneNewsEsterne} />
            <PrivateLayout path="/area-riservata/gestione-news-esterne/modifica/:idNews" exact component={ModificaNewsEsterna} />
            <PrivateLayout path="/area-riservata/gestione-news-esterne/aggiungi" exact component={AggiungiNewsEsterna} />
            <PrivateLayout path="/area-riservata/gestione-news-esterne/dettaglio/:id" exact component={DettaglioNewsEsterna} />
            {/* FINE PATH NEWS */}

            {/* INIZIO PATH FAQ E GESTIONE FAQ */}
            <PrivateLayout path="/area-riservata/faq" exact component={GestioneFaq} />
            <PrivateLayout path="/area-riservata/gestione-faq" exact component={GestioneFaq} />
            <PrivateLayout path="/area-riservata/gestione-faq/aggiungi" exact component={AggiungiFaq} />
            <PrivateLayout path="/area-riservata/gestione-faq/modifica/:idFaq" exact component={ModificaFaq} />
            <PrivateLayout path="/area-riservata/gestione-faq-esterne" exact component={GestioneFaqPubbliche} />
            <PrivateLayout path="/area-riservata/gestione-faq-esterne/aggiungi" exact component={AggiungiFaqPubblica} />
            <PrivateLayout path="/area-riservata/gestione-faq-esterne/modifica/:idFaq" exact component={ModificaFaqPubblica} />
            {/* FINE PATH FAQ E GESTIONE FAQ */}

            {/* INIZIO PATH MATRICE CORRISPONDENZA */}
            <PrivateLayout path="/area-riservata/matrice-corrispondenza-profili" exact component={MatriceCorrispondenzaProfili} />
            <PrivateLayout path="/area-riservata/matrice-corrispondenza-profili/carica-lista-profili" exact component={CaricaListaProfili} />
            <PrivateLayout path="/area-riservata/matrice-corrispondenza-profili/carica-lista-profili/:idBatch/dettaglio" exact component={DettaglioCaricaProfili} />
            <PrivateLayout path="/area-riservata/matrice-corrispondenza-profili/elaborazione-ai" exact component={ElaborazioneCorrispondenzeAI} />
            {/* FINE PATH MATRICE CORRISPONDENZA */}

            {/* INIZIO PATH CHAT */}
            <PrivateLayout path="/area-riservata/chat" exact component={Chat} />
            {/* FINE PATH CHAT */}

            {/* INIZIO PATH HELP DESK */}
            <PrivateLayout path="/area-riservata/help-desk" exact component={HelpDesk} />
            {/* FINE PATH HELP DESK */}

            {/* INIZIO PATH TUTORIAL */}
            <PrivateLayout path="/area-riservata/tutorial" exact component={Tutorial} />
            {/* FINE PATH TUTORIAL */}

            <PrivateLayout path="/area-riservata/*" component={PageNotFound} />
            {/* FINE PATH AREA RISERVATA  */}

            <PublicLayout path="*" component={PageNotFound} />
          </Switch>
        </Suspense>
        {/* COMPONENTE CHE FA TORNARE SU AD OGNI CAMBIO PAGINA */}
        <ScrollToTop />
      </Router>
      {/* COMPONENTE GRAFICO PER TORNARE SU */}
      <TornaSu />
    </div>
  );
}

export default App;
