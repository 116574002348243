import { Form } from 'react-bootstrap';
import ModaleCentroVerticale from '../../../components/modali/ModaleCentroVerticale';

function ModaleEmail({
  modificaUtente,
  onChangeUtente,
  modalShow,
}) {
  return (
    <ModaleCentroVerticale
      modalTitle="Email mancante"
      modalBody={(
        <div>
          <p>
            <b>Attenzione</b>
            : l&apos;inserimento di un&apos;email
            valida è obbligatorio per procedere.
            Assicurati di compilare il campo email.
          </p>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                id="desc_email"
                placeholder="mario.rossi@email.com"
                onChange={(e) => onChangeUtente(e)}
              />
            </Form.Group>
          </Form>
        </div>
      )}
      show={modalShow}
      onConfirm={() => modificaUtente()}
      disabledClose
      labelConfirm="CONFERMA"
      backdrop="static"
      keyboard={false}
      closeButton={false}
    />
  );
}

export default ModaleEmail;
