import api from '../utils/api';
import createQueryString from '../utils/createQueryString';

const accreditamentoUrl = process.env.REACT_APP_ACCREDITAMENTO_URL;

async function getUtenti(filtri, offset, limit) {
  const filters = { ...filtri, offset, limit };
  const params = createQueryString(filters);
  return api.get(`${accreditamentoUrl}/gestione-utente?${params}`, { withCredentials: true })
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function creaUtente(newUtente) {
  return api.post(`${accreditamentoUrl}/gestione-utente`, newUtente)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getUtenteById(id, filters) {
  const params = createQueryString(filters);
  return api.get(`${accreditamentoUrl}/gestione-utente/${id}?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getUtenteByIdentificativo(identificativo) {
  return api.get(`${accreditamentoUrl}/gestione-utente/utente/${identificativo}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaUtente(utente) {
  return api.put(`${accreditamentoUrl}/gestione-utente/${utente.id}`, utente)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getUtenteByIdUtente() {
  return api.get(`${accreditamentoUrl}/gestione-utente/utente`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaUtentePerUtente(utente) {
  return api.put(`${accreditamentoUrl}/gestione-utente/utente`, utente)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaUtente(id) {
  return api.delete(`${accreditamentoUrl}/gestione-utente/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getBatch(filtri, offset, limit) {
  const filters = { ...filtri, offset, limit };
  delete filters.codi_tipo_batch;
  let params = createQueryString(filters);
  if (filtri.codi_tipo_batch) {
    filtri.codi_tipo_batch.forEach((el) => {
      params += `&codi_tipo_batch=${el}`;
    });
  }
  return api.get(`${accreditamentoUrl}/gestione-batch?${params}`, { withCredentials: true })
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function creaBatch(newBatch) {
  const formData = new FormData();
  formData.append('codi_tipo_batch', newBatch.codi_tipo_batch);
  formData.append('file', newBatch.file);
  return api.post(`${accreditamentoUrl}/gestione-batch`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getBatchById(id) {
  return api.get(`${accreditamentoUrl}/gestione-batch/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const utenteService = {
  getUtenti,
  creaUtente,
  getUtenteById,
  getUtenteByIdentificativo,
  modificaUtente,
  getUtenteByIdUtente,
  modificaUtentePerUtente,
  eliminaUtente,
  getBatch,
  creaBatch,
  getBatchById,
};

export default utenteService;
