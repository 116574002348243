import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';
import { useCookies } from 'react-cookie';
import logoEu from '../../images/logo-eu.png';
import logoDfp from '../../images/funzione-pubblica.svg';
import logoPnrr from '../../images/logo-pnrr.png';
import logoFormez from '../../images/logo-formez.png';
import logoGoverno from '../../images/logo-governo.png';

function HeaderHtml() {
  const [cookies] = useCookies(['cookie-name']);

  return (
    <header className="it-header-wrapper">
      <div className="it-header-slim-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="it-header-slim-wrapper-content">
                <a className="d-lg-block navbar-brand" href="http://www.funzionepubblica.gov.it/" target="_blank" rel="noreferrer">Dipartimento della funzione pubblica</a>
                <div className="it-access-top-wrapper">
                  {cookies.loggedIn
                    ? (
                      <Link className="btn btn-primary btn-full" to="/area-riservata/scelta-identita" title="Accedi all'area riservata">
                        <FontAwesomeIcon aria-hidden="true" icon={['fas', 'user-circle']} size="lg" />
                        &nbsp;
                        Torna all&apos;area riservata
                      </Link>
                    )
                    : (
                      <Link className="btn btn-primary btn-full" to="/pagina-login" title="Accedi all'area riservata">
                        <FontAwesomeIcon aria-hidden="true" icon={['fas', 'user-circle']} size="lg" />
                        &nbsp;
                        Accedi all&apos;area riservata
                      </Link>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="it-nav-wrapper">
        <div className="it-header-center-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="it-header-center-content-wrapper">
                  <div className="it-brand-wrapper">
                    <Link to="/" title="Vai alla pagina: Homepage">
                      <svg width="82" height="82" className="icon" aria-hidden="true">
                        <image xlinkHref={logoGoverno} alt="Logo Governo Italiano" />
                      </svg>
                      <div className="it-brand-text">
                        <div className="it-brand-title" style={{ fontSize: '48px' }}>RiVa</div>
                        <div className="it-brand-tagline d-none d-lg-block">La gestione strategica delle Risorse umane per creare Valore pubblico</div>
                      </div>
                    </Link>
                  </div>
                  <div className="d-flex flex-column align-items-end h-100">
                    <div className="it-right-zone my-3">
                      <div className="it-socials d-none d-sm-flex">
                        <span>Seguici su</span>
                        <ul style={{ padding: '5px' }}>
                          <li style={{ padding: '5px' }}>
                            <a
                              href="https://www.facebook.com/DipartimentoFunzionePubblica"
                              target="_blank"
                              title="Collegamento a sito esterno - Facebook - si apre una nuova finestra"
                              rel="noreferrer"
                              aria-label="Facebook"
                            >
                              <FontAwesomeIcon aria-hidden="true" icon={['fab', 'facebook']} size="lg" color="white" />
                            </a>
                          </li>
                          <li style={{ padding: '5px' }}>
                            <a
                              href="https://twitter.com/FunzPub"
                              target="_blank"
                              title="Collegamento a sito esterno - Twitter - si apre una nuova finestra"
                              rel="noreferrer"
                              aria-label="Twitter"
                            >
                              <FontAwesomeIcon aria-hidden="true" icon={['fab', 'x-twitter']} size="lg" color="white" />
                            </a>
                          </li>
                          <li style={{ padding: '5px' }}>
                            <a
                              href="https://www.instagram.com/dipartimento_funzione_pubblica/"
                              target="_blank"
                              title="Collegamento a sito esterno - Instagram - si apre una nuova finestra"
                              rel="noreferrer"
                              aria-label="Instagram"
                            >
                              <FontAwesomeIcon aria-hidden="true" icon={['fab', 'instagram']} size="lg" color="white" />
                            </a>
                          </li>
                          <li style={{ padding: '5px' }}>
                            <a
                              href="https://www.youtube.com/channel/UCIGLJG5C6ghZp-V-AgWwGCg"
                              target="_blank"
                              title="Collegamento a sito esterno - Youtube - si apre una nuova finestra"
                              rel="noreferrer"
                              aria-label="YouTube"
                            >
                              <FontAwesomeIcon aria-hidden="true" icon={['fab', 'youtube']} size="lg" color="white" />
                            </a>
                          </li>
                          <li style={{ padding: '5px' }}>
                            <a
                              href="https://it.linkedin.com/company/dipartimento-della-funzione-pubblica"
                              target="_blank"
                              title="Collegamento a sito esterno - LinkedIn - si apre una nuova finestra"
                              rel="noreferrer"
                              aria-label="LinkedIn"
                            >
                              <FontAwesomeIcon aria-hidden="true" icon={['fab', 'linkedin']} size="lg" color="white" />
                            </a>
                          </li>
                          <li style={{ padding: '5px' }}>
                            <a
                              href="https://web.telegram.org/#/im?p=@funzionepubblica"
                              target="_blank"
                              title="Collegamento a sito esterno - Telegram - si apre una nuova finestra"
                              rel="noreferrer"
                              aria-label="Telegram"
                            >
                              <FontAwesomeIcon aria-hidden="true" icon={['fab', 'telegram']} size="lg" color="white" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="logo-long d-none d-lg-block mb-3">
                      <img height="40px" className="me-3" src={logoEu} alt="Logo Next Generation EU" />
                      <img height="40px" className="me-3" src={logoDfp} alt="Logo Dipartimento della Funzione Pubblica" />
                      <img height="40px" className="me-3" src={logoPnrr} alt="Logo PNRR" />
                      <img height="40px" className="me-3" src={logoFormez} alt="Logo Formez" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="it-header-navbar-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <nav className="navbar navbar-expand-lg has-megamenu" aria-label="Navigazione principale">
                  <button className="custom-navbar-toggler" type="button" aria-controls="nav4" aria-expanded="false" aria-label="Mostra/Nascondi la navigazione" data-bs-toggle="navbarcollapsible" data-bs-target="#nav4">
                    <svg className="icon bg-override"><use href="/navbar-icons/sprites.svg#it-burger" /></svg>
                  </button>
                  <div className="navbar-collapsable" id="nav4" style={{ display: 'none' }}>
                    <div className="overlay" style={{ display: 'none' }} />
                    <div className="close-div">
                      <button className="btn close-menu" type="button">
                        <span className="visually-hidden">Nascondi la navigazione</span>
                        <svg className="icon"><use href="/navbar-icons/sprites.svg#it-close-big" /></svg>
                      </button>
                    </div>
                    <div className="menu-wrapper">
                      <ul className="navbar-nav">
                        <li className="nav-item"><Link className="nav-link" to="/progetto"><span id="headerSpan">Il Progetto</span></Link></li>
                        <li className="nav-item"><Link className="nav-link" to="/approfondimenti"><span id="headerSpan">Approfondimenti</span></Link></li>
                        <li className="nav-item"><Link className="nav-link" to="/area-amministrazioni"><span id="headerSpan">Area Amministrazioni</span></Link></li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderHtml;
