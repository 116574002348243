/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import Header from '../../components/HeaderPrivata';
import './styles.css';
import { clear, getItem, removeItem } from '../../utils/storage';
import Footer from '../../components/Footer/FooterHtml';
import { Context, ContextProvider } from '../../utils/Context';
import ErrorBoundary from '../../components/ErrorBoundary';
import Error from '../../components/Alert';
import Sidebar from '../../components/Sidebar';
import history from '../../utils/history';
import Spinner from '../../components/Spinner';
import authProviderService from '../../services/authProviderService';
import { globalVariables } from '../../utils/refreshToken';
import api from '../../utils/api';
import { controlla } from '../../utils/verificaPermessi';
import { client, setAndStartMatrix } from '../../utils/matrix';
import chatService from '../../services/chatService';
import utenteService from '../../services/utentiService';
import ModaleEmail from './components/ModaleEmail';

function PrivateLayout({ component: Component, code, ...rest }) {
  const showSidebar = history.location.pathname !== '/area-riservata/scelta-identita' && history.location.pathname !== '/area-riservata/account';
  const handleOpen = () => {
    document.body.classList.toggle('sb-sidenav-toggled');
  };
  const [context, setContext] = useContext(Context);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [utenteModified, setUtenteModified] = useState({
    trusted: true,
    untrusted: [],
  });
  const [utente, setUtente] = useState({});
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);

  async function getNuovoRefreshToken() {
    if (!globalVariables.refreshToken || globalVariables.refreshToken === '') {
      await authProviderService.nuovoRefreshToken()
        .then((response) => {
          globalVariables.refreshToken = response.refresh_token;
        })
        .catch(() => {
          removeItem('user');
          removeCookie('loggedIn', { path: '/' });
        });
    }
  }

  async function getNuovoCsrfToken() {
    if (!api.defaults.headers.common['X-CSRF-Token']) {
      await authProviderService.nuovoCsrfToken()
        .then((response) => {
          api.defaults.headers.common['X-CSRF-Token'] = response.csrfToken;
        })
        .catch(() => {
          removeItem('user');
          removeCookie('loggedIn', { path: '/' });
        });
    }
  }

  async function startClientMatrix() {
    if (!client) {
      try {
        const responseMatrix = await chatService.loginMatrix((JSON.parse(getItem('user')))?.identificativo);
        setAndStartMatrix(responseMatrix);
      } catch (err) {
        console.error(err);
      }
    }
  }

  async function getUtenteByIdUtente() {
    try {
      const response = await utenteService.getUtenteByIdUtente();
      setUtente(response);
      if (!response.desc_email) {
        history.push('/area-riservata/scelta-identita');
        setModalShow(true);
      }
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function modificaUtente() {
    if (utenteModified?.desc_email) {
      utenteService.modificaUtentePerUtente(utenteModified)
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          setModalShow(false);
          getUtenteByIdUtente();
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
    }
  }

  const onChangeUtente = (e) => {
    const { value } = e.target;
    setUtenteModified((prevState) => ({
      ...prevState,
      desc_email: value,
    }));
  };

  useEffect(() => {
    getNuovoRefreshToken();
    getNuovoCsrfToken();
    setTimeout(() => {
      startClientMatrix();
      setShow(true);
    }, 500);
    // if (('Notification' in window)) {
    //   Notification.requestPermission();
    // }
  }, []);

  useEffect(() => {
    if (code && !controlla(code)) {
      history.push('/area-riservata');
    }
  }, [window.location.href]);

  // useEffect(() => {
  //   if (!utente?.desc_email) {
  //     setModalShow(true);
  //     history.push('/area-riservata/scelta-identita');
  //   }
  // }, [utente]);

  // useEffect necessario per effettuare il logout quando si chiude il browser
  useEffect(() => () => {
    if (!utente || Object.keys(utente).length === 0) {
      getUtenteByIdUtente();
    }
    window.addEventListener('unload', () => {
      if (!cookies.loggedIn) {
        client?.logout();
        clear();
      }
    });
  });

  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (getItem('user')) {
          return (
            <ContextProvider>
              <div className="d-flex" id="wrapper">
                {(showSidebar && show && utente?.desc_email) ? <Sidebar /> : null}
                <div id="page-content-wrapper">
                  <Header handleOpen={handleOpen} showSidebarButton={showSidebar} />
                  <Error />
                  <Container className="my-4" id="content" role="main">
                    <ErrorBoundary>
                      {!utente?.desc_email && (
                        <ModaleEmail
                          modificaUtente={modificaUtente}
                          onChangeUtente={onChangeUtente}
                          modalShow={modalShow}
                        />
                      )}
                      {(show && utente?.desc_email) && <Component {...matchProps} />}
                    </ErrorBoundary>
                  </Container>
                </div>
              </div>
              <Footer />
              <Spinner />
            </ContextProvider>
          );
        }
        return (
          <Redirect
            to={{
              pathname: '/error-unauthorized',
              state: {
                from: matchProps.location,
              },
            }}
          />
        );
      }}
    />
  );
}

export default PrivateLayout;
